import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { notificationsActions } from './notifications.action';

export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}


const notificationsSlice = createSlice({
  name: "projects",
  initialState: {
    error: "",
    notificationsConfig: null,

    offices: [],
    triggerType: [],
    notificationKeywords: [],
    userList: [],

    notificationsList: [],
    notificationDetails: null,

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
        // -------- cases of notification config api data -------
        .addCase(notificationsActions.getRuleConfigAction.pending, (state, action) => {
          state.error = "";
          state.notificationsConfig = null;
        })
        .addCase(notificationsActions.getRuleConfigAction.fulfilled, (state, action) => {
          if (action?.payload?.data?.status===200) {
            state.error = "";

              //   notification config
              state.notificationsConfig = action?.payload?.data?.data;

              state.offices = action?.payload?.data?.data?.offices?.map((office: any) => {
                  return {
                      label: office?.name,
                      value: office?.id
                  }
              });

              state.triggerType = action?.payload?.data?.data?.triggerType?.map((trigger: any) => {
                  return {
                      ...trigger,
                      label: trigger?.type,
                      value: trigger?.type,
                  }
              });
            
              state.notificationKeywords = action?.payload?.data?.data?.notificationKeywordsList?.map((keyword: any) => {
                  return {
                      ...keyword,
                      name: keyword?.type,
                      id: keyword?.type,
                  }
              });

              state.userList = action?.payload?.data?.data?.userList?.map((user: any) => {
                  return {
                      label: user?.label,
                      value: user?.value,
                  }
              });

          } else {
            state.error = "No records found.";
            state.notificationsConfig = null;
          }
        })
        .addCase(notificationsActions.getRuleConfigAction.rejected, (state, action:any) => {        
          state.error = action?.payload?.data?.message || "";
          state.notificationsConfig = null;
        })

        // -------- cases of notifications listing api data -------
        .addCase(notificationsActions.getNotificationsListAction.pending, (state, action) => {
          state.error = "";
          state.notificationsList = [];
        })
        .addCase(notificationsActions.getNotificationsListAction.fulfilled, (state, action) => {
          if (action?.payload?.data?.status===200) {
            state.error = "";
            state.notificationsList = action?.payload?.data?.data;
          } else {
            state.error = "No records found.";
            state.notificationsList = [];
          }
        })
        .addCase(notificationsActions.getNotificationsListAction.rejected, (state, action:any) => {        
          state.error = action?.payload?.data?.message || "";
          state.notificationsList = [];
        })

        // -------- cases of notification details api data -------
        .addCase(notificationsActions.getNotificationDetailsAction.pending, (state, action) => {
          state.error = "";
          state.notificationDetails = null;
        })
        .addCase(notificationsActions.getNotificationDetailsAction.fulfilled, (state, action) => {
          if (action?.payload?.data?.status===200) {
            state.error = "";
            state.notificationDetails = action?.payload?.data?.data;
          } else {
            state.error = "No records found.";
            state.notificationDetails = null;
          }
        })
        .addCase(notificationsActions.getNotificationDetailsAction.rejected, (state, action:any) => {        
          state.error = action?.payload?.data?.message || "";
          state.notificationDetails = null;
        })

        // -------- cases of notification activate api data -------
        .addCase(notificationsActions.activateNotificationAction.pending, (state, action) => {
          state.error = "";
          // state.notificationDetails = null;
        })
        .addCase(notificationsActions.activateNotificationAction.fulfilled, (state, action) => {
          if (action?.payload?.data?.status===200) {
            state.error = "";
            state.notificationsList = action?.payload?.data?.data;
          }
        })
        .addCase(notificationsActions.activateNotificationAction.rejected, (state, action:any) => {        
          state.error = action?.payload?.data?.message || "";
          // state.notificationDetails = null;
        })

  },
  
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;


export default notificationsSlice.reducer;
