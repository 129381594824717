import { useEffect } from 'react';
import RoutesWapper  from './routes/Index.routes';
import ThemeProvider from "./theme";
import "../src/assets/scss/style.scss"
function App() {

  // to remove tableau token when tab is being closed
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      // Perform token removal or any other cleanup logic
      localStorage.removeItem("tableau_token"); // Example: remove token from localStorage
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <ThemeProvider>
      <RoutesWapper />
    </ThemeProvider>
  );
}

export default App;
