import http from "../http";

// Export all Auth Service
export const authService = {
  authActionService,
  impersonificationService,
  sendOtpService,
  verifyOtpService,
  resetPasswordService,
};

// Login Service
function authActionService(payload : {email: string, password: string}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/login/`, {
      ...payload,
      params: {
        use_x_Refresh_Token: false
      }
    });
}

// Impersonification Service
function impersonificationService(payload : any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/get-user-token/${payload?.id}`);
}

// Customer Send OTP services
function sendOtpService(payload : {email: string}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/send-reset-code/`, {
      ...payload
    });
}

// Verify OTP services
function verifyOtpService(payload : {code: string}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/verify-code/`, {
      ...payload
    });
}

// Reset Password services
function resetPasswordService(payload : {code: string, password: string, confirmPassword: string}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/reset-password/`, {
      ...payload
    });
}

 

