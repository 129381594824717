import http from "../http";

// Export all Project Service
export const projectsService = {
  projectsAutocompleteService,
  getUsersListService,
  createProjectService,
  updateProjectService,
  projectListService,
};

// Project Autocomplete Service
function projectsAutocompleteService(query: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/autcomplete?term=${query}`, );
}

// Project User list Service
function getUsersListService() {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/user-list`, {
  });
}

// Active Projects list Service
function projectListService(payload: {perPage: string, page: string, filters: any}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects?perPage=${payload.perPage}&page=${payload.page}&term=${payload?.filters?.term}`, {
  });
}

// Create Projects Service
function createProjectService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/projects`, {
    ...payload
  });
}

// Update Project Service
function updateProjectService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.projectId}`, payload?.data);
}

 

