import { createAsyncThunk } from '@reduxjs/toolkit';
import { fmDashboardService } from '../../../services/financeManagement/dashboard.service';
import { showHideLoader } from '../../common/common.slice';


// Get all invoices list action
const getAllInvoicesListingAction = createAsyncThunk('fmProjectsSlice/getAllInvoicesListingAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    return fmDashboardService.getAllInvoiceListing({payload})
        .then((response: any) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});


// Export all Projects Action
export const fmDashboardActions = {
    getAllInvoicesListingAction
}