import { createAsyncThunk } from '@reduxjs/toolkit';
import { officesService } from '../../services/offices/offices.service';
import { showHideLoader } from '../common/common.slice';


// Get Offices list Action
const getOfficesAction = createAsyncThunk(
  'officeSlice/getOfficesAction', (payload: { perPage: string, page: string}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return officesService.getOfficesListService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Get Offices details Action
const getOfficeDetailsAction = createAsyncThunk(
  'officeSlice/getOfficeDetailsAction', (payload: { id: string}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return officesService.getOfficeDetailsService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Create Office Action
const createOfficeAction = createAsyncThunk(
  'officeSlice/createOfficeAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return officesService.createOfficeService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Update Office Action
const updateOfficeAction = createAsyncThunk(
  'officeSlice/updateOfficeAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return officesService.updateOfficeService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);


// Export all Projects Action
export const officesActions = {
  getOfficesAction,
  getOfficeDetailsAction,
  createOfficeAction,
  updateOfficeAction
}