import moment from 'moment';
import { amountMask} from "./helperfunction/helper";

// Function to validate permissions
const isAccessible = (module_name, permissionsArray, permissions) => {
  // not required for now. if multiple permissions need to be checked. we will use this.
  function allTrue(obj){
    for(var o in obj){
      if(obj[o]){
        return true;
      }
      let x = permissions?.map(ele => {
        if(obj[ele]){
          return true
        }
        return null
      });
      return x;
    }
  }

  if(Object.keys(permissionsArray.permissions).length > 0){
    return allTrue(permissionsArray.permissions?.[module_name])
  } 
  return false;
}

// check module permissions
function checkPermission(moduleName, permissionName, permissionsArray) {
    // Check if the module exists and if the permission is true
    return permissionsArray[moduleName] && permissionsArray[moduleName][permissionName];
}

// Debounce function to create closure around the function
const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

// total amount
const sumData = (array) => {
  let reducedAmount = 0;
  if (array) {
    reducedAmount = array.reduce((accum, curr) => {
      let amount = curr.amount;
      amount = Number(amount);
      return accum + amount;
    }, 0)
  }
  return Number(reducedAmount.toFixed(2));
  // return formatAmount(reducedAmount)
  // .toLocaleString(undefined, {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // });
}

// total percentage
const percentageSum = (array) => {
  let reducedPercentage = 0
  if (array) {
    reducedPercentage = array.reduce((accum, curr) => {
      let percentage = curr.percentage;
      percentage = Number(percentage);
      return accum + percentage;
    }, 0)
  }
  return reducedPercentage.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

// percentage amount according to project total
const percentageAmount = (projectTotal, percentageSum) => {
  const totalPercentageAmount = (projectTotal * percentageSum) / 100;
  return formatAmount(totalPercentageAmount)
  .toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

// Format amount
const formatAmount = (amount) => {
  // const suffixes = ['', 'k', 'm', 'b', 't', ];
  // let suffixIndex = 0;

  // convert if amount equals 1M 
  // while (amount >= 100000000 && suffixIndex < suffixes.length - 1) {
  //   amount /= 1000;
  //   suffixIndex++;
  // }

  // Use the toFixed() method to round the amount to two decimal places
  const formattedAmount = amountMask(amount);

  return `${formattedAmount}`;
  // return `${formattedAmount} ${suffixes[suffixIndex]}`;
}

const formatAmountSuffix = (amount, precision) => {
  // const suffixes = ['', 'k', 'm', 'b', 't', ];
  // let suffixIndex = 0;

  // convert if amount equals 1M 
  // while (amount >= 100000000 && suffixIndex < suffixes.length - 1) {
  //   amount /= 1000;
  //   suffixIndex++;
  // }

  // Use the toFixed() method to round the amount to two decimal places
  const formattedAmount = amountMask(amount, null, precision);
  return `${formattedAmount}`;
  // return `${formattedAmount} ${suffixes[suffixIndex]}`;
}

// phone number format
const formatPhoneNumber = (number) => {
  const cleaned = number === null ? "0000000000" : ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ` (${match[1]})${match[2]}-${match[3]}`;
  }
  return number;
};

const formatPhoneNumberField = (number) => {
  const cleaned = number === null ? "" : ('' + number).replace(/\D/g, '').slice(0, 10);
  
  // If the user deletes down to less than 4 digits, we shouldn't format
  if (cleaned.length < 4) {
    return cleaned;  // No need to format yet
  }

  const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  
  if (match) {
    // Format based on the available parts of the match
    if (match[3]) {
      return `(${match[1]}) ${match[2]} ${match[3]}`;
    } else if (match[2]) {
      return `(${match[1]}) ${match[2]}`;
    } else {
      return `(${match[1]})`;
    }
  }
  return number;
};

const formatDate = (dateVal, format, dFormat) => {
  if (dateVal === null || dateVal === undefined) {
    return "";
  }

  let momentObj;
  if (typeof dateVal === 'string' && dateVal.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?$/)) {
    // If dateVal is an ISO string
    momentObj = moment.utc(dateVal, dFormat ? dFormat : "");
  } else {
    // If dateVal is not an ISO string
    momentObj = moment(dateVal, dFormat ? dFormat : "");
  }

  return momentObj.format(format);
}

// scroll to top
const scrollToTop = (listRef) => {
  if(listRef.current) {
    listRef.current.scrollTo(0, 0)
  }
}

// status styles
const statusClass = (statusId) => {
  let statusClass = "";
      switch (statusId) {
        case "ACCEPTED": statusClass = "status-green"; break;
        case "DRAFT": statusClass = "status-light-grey"; break;
        case "SIGNATURE_PENDING": statusClass = "status-orange"; break;
        case "DECLINED": statusClass = "status-light-red"; break;
        case "CANCELED": statusClass = "status-light-red"; break;
        case "EXPIRED": statusClass = "status-light-red"; break;
        case "BIDDING_CLOSED": statusClass = "status-green"; break;
        case "BIDDING_OPEN": statusClass = "status-light-grey"; break;
        case "VIEWED": statusClass = "status-orange"; break;
        default: statusClass = ""
      }

      return statusClass;
}

const handleImmediateDownload = (url) => {
  // using Java Script method to get PDF file 
  fetch(url).then(response => {
    response.blob().then(blob => {
      // Extract the file name from the URL
      const urlObject = new URL(url);
      const pathName = urlObject.pathname;
      const fileName = pathName.substring(pathName.lastIndexOf('/') + 1);
      // Creating new object of PDF file 
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values 
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = fileName;
      alink.click();
    })
  })
}

// concat title
const concatedTitle = (title) => {
  let updatedTitle = title;
  const parts = title.split(","); // Split the string at the first comma
  // if parts length is greater than 1
  if (parts.length > 1) {
    // if only empty space is there after comma
    if (parts[1] === " ") {
      updatedTitle = title.replace(/,/g, "");
    } else {
      updatedTitle = parts[0].trim() + "...";
    }
  } else {
    updatedTitle = title; // No comma found, use the original string
  }

  return updatedTitle;
}

// view as pdf
const handleViewAsPdf = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = "_blank";
  link.click();
}

// arrow keys custom behaviour for number input
const handleArrowKey = (number, type) => {
  if(type)
    return number+1;
  return number-1;
}

// calculate working days
const calculateWorkingDays = (startDate, endDate) => {
  let currentDate = startDate;
  let workingDays = 0;

  while (currentDate?.isBefore(endDate) || currentDate?.isSame(endDate, 'day')) {
    // Check if the current day is not Saturday (6) or Sunday (0)
    if (currentDate?.day() !== 6 && currentDate?.day() !== 0) {
      workingDays++;
    }
    currentDate = currentDate?.add(1, 'day'); // Move to the next day
  }
  return workingDays;
}

const calculateFutureWorkingDate = (startDate, numWorkingDays) => {
  let currentDate = startDate;
  let remainingWorkingDays = numWorkingDays;

  while (remainingWorkingDays > 0) {
    currentDate = currentDate.add(1, 'day');
    
    // Check if the current day is not Saturday (6) or Sunday (0)
    if (currentDate.day() !== 6 && currentDate.day() !== 0) {
      remainingWorkingDays--;
    }
  }
  return currentDate;
}

const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phoneNumber);
}

const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Export all util functions
export const comminUtils = {
  isAccessible,
  checkPermission,
  debounce,
  sumData,
  percentageSum,
  percentageAmount,
  formatAmount,
  formatAmountSuffix,
  formatPhoneNumber,
  formatPhoneNumberField,
  formatDate,
  scrollToTop,
  statusClass,
  handleImmediateDownload,
  handleArrowKey,
  calculateWorkingDays,
  calculateFutureWorkingDate,
  handleViewAsPdf,
  concatedTitle,
  isValidPhoneNumber,
  capitalizeFirstChar
}
