import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store/store';
import { rolesActions } from './roles.actions';

export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}


const rolesSlice = createSlice({
  name: "getRoles",
  initialState: {
    rolesListData: [],
    roleCreatedData: null,
    pagination: {},
    error: "",
    loading: false,
    createRoleError: "",
    createRoleErrorArray: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       // -------- cases of get all roles api data -------
       .addCase(rolesActions.getRolesAction.pending, (state, action) => {
        state.error = "";
        state.rolesListData = [];
        state.loading = true;
      })
      .addCase(rolesActions.getRolesAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.status === 200) {
          state.rolesListData= action?.payload?.data?.data;
          state.pagination= action?.payload?.data?.pagination;
          state.error = "";
        } else {
          state.error = "unable to fetch roles.";
        }
      })
      .addCase(rolesActions.getRolesAction.rejected, (state, action:any) => {
        state.loading = false;
        state.rolesListData = [];
        state.error = action?.payload?.data?.message || "";
      })
      // -------- cases of Create role api data -------
      .addCase(rolesActions.createRolesAction.pending, (state, action) => {
        state.error = "";
        state.roleCreatedData = null;
        state.loading = true;
        state.createRoleErrorArray = [];
      })
      .addCase(rolesActions.createRolesAction.fulfilled, (state, action) => {
        state.createRoleErrorArray = [];
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.roleCreatedData= action?.payload?.data?.message;
          state.createRoleError=""
        }else{
          state.createRoleError = "Unable to create role.";
        }
      })
      .addCase(rolesActions.createRolesAction.rejected, (state, action: any) => {
        state.loading = false;
        state.roleCreatedData = null;
        state.createRoleError = action?.payload?.data?.message;
        state.createRoleErrorArray = action?.payload?.data?.error?.details?action?.payload?.data?.error?.details:[];
      });
  },
  
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;


export default rolesSlice.reducer;
