import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CustomInputField from "../../../components/form/Inputs/Input";
import { snackbarHandler } from '../../../components/Snackbar/SnackbarHandler';

import { authActions } from "../../../redux/auth/auth.actions";
import { useAppDispatch } from "../../../redux/store/store";
import { unsetLoginModal, logout } from '../../../redux/auth/auth.slice';
import { userObject } from "../../../redux/auth/auth.selectors";
import { useAppSelector } from "../../../redux/store/store";


import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close.svg";
import { ReactComponent as AlertIcon } from "../../../assets/images/icons/alert-icon.svg";

export default function LoginModal(props: any) {

    const { modalObj } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userInfo = useAppSelector<any>(userObject);

    useEffect(() => {
        setValues({
            ...values,
            userName: userInfo?.email
        })
    }, [userInfo])

    const [showPassword, setShowPassword] = useState(false);
    // Default form values
    const [values, setValues] = useState({
        password: '',
        userName: '',
        showPassword: false,
    });
    // Default form errors
    const [errors, setErrors] = useState({
        password: '',
        userName: '',
    });
    // show password text
    const handleClickShowPassword = () => {
        setShowPassword(true);
    }
    // hide password text
    const handleMouseDownPassword = () => {
        setShowPassword(false);
    }

    // input onChange function
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setValues({
            ...values,
            [field]: e.target.value
        })
    }

    // input onBlur function
    const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        var value = e.target.value;
        if (!value) {
            setErrors({
                ...errors,
                [field]: true
            })
        }
        if (field === "userName") {
            if (!value.trim().toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setErrors({
                    ...errors,
                    [field]: "Invalid Email."
                })
            } else {
                setErrors({
                    ...errors,
                    [field]: ""
                })
            }
        }
        if (field === "password") {
            if (value.length < 6) {
                setErrors({
                    ...errors,
                    [field]: "Password must be atleast 6 character long"
                })
            } else {
                setErrors({
                    ...errors,
                    [field]: ""
                })
            }
        }
    }

    // form submit function
    const handleSubmit = (e: any) => {
        e.preventDefault();
        let payload = {
            email: values.userName,
            password: values.password
        }
        // check if the same user is being authenticated
        // and accordingly use the suitable action.
        // also check if the request was of the type where page needs to be refreshed

        if (!modalObj?.refresh && (userInfo?.email === payload?.email)) {
            dispatch(authActions.replaceTokenAction(payload))
                .then((res: any) => {
                    if (res?.payload?.data?.status === 200) {
                        // navigate(window.location.pathname);
                        dispatch(unsetLoginModal());
                    } else {
                        // render error message
                        snackbarHandler(res, dispatch);
                    }
                })
                .catch((err: any) => {
                    // show error message here.
                    snackbarHandler(err, dispatch);
                })
        } else {
            dispatch(authActions.loginAction(payload))
                .then((res: any) => {
                    if (res?.payload?.data?.status === 200) {

                        // reload the page
                        window.location.reload();
                        dispatch(unsetLoginModal());
                    } else {
                        // render error message
                        snackbarHandler(res, dispatch);
                    }
                })
                .catch((err: any) => {
                    // show error message here.
                    snackbarHandler(err, dispatch);
                })
        }

        return false;
    };

    const handleClose = () => {
        // close modal
        dispatch(unsetLoginModal());
        // log out user
        dispatch(logout());
        // localStorage.clear();
        navigate('/login');
    }

    return (
        <Modal
            open={modalObj?.status}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='modal-documents modal-proposal-room-info-warning'
        >
            <Box className="modal-content">
                <Button className="btn-close">
                    <CloseIcon
                        onClick={handleClose}
                    />
                </Button>
                <div className="modal-header">
                    <h2><AlertIcon />Session Timeout</h2>
                </div>

                <div className='mid-content'>
                    <div className='login-top-headding mb-12'>
                        Your session has expired due to inactivity. Please log in again to continue.
                    </div>
                    <div className='login-form-wrap'>
                        <form onSubmit={handleSubmit} className="loginForm" >
                            <div className='form-group-item form-group'>
                                <div className='content-title'>
                                    <h2>Username or Email Address</h2>
                                </div>
                                <CustomInputField
                                    autoComplete="userName"
                                    name="userName"
                                    required
                                    fullWidth
                                    onChange={handleInputChange}
                                    value={values.userName}
                                    id="userName"
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleInputBlur(e, "userName")}
                                    label=""
                                    placeHolder="e.g. hello@chapter.com"
                                    error={errors}
                                    disabled={!userInfo?.email}
                                />
                            </div>
                            <div className='form-group-item'>
                                <div className='content-title'>
                                    <h2>Password</h2>
                                </div>
                                <CustomInputField
                                    type='password'
                                    name='password'
                                    required
                                    // showForgotPassword={true}
                                    fullWidth
                                    id="password"
                                    onChange={handleInputChange}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleInputBlur(e, "password")}
                                    label=""
                                    showPassword={showPassword}
                                    placeHolder="e.g. Opensesame10$"
                                    inputProps={{
                                        'autoComplete': 'new-password',
                                    }}
                                    error={errors}
                                    value={values.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </form>
                    </div>
                </div>

                {/* Footer */}
                <div className="modal-footer">
                    <div className="button-group">
                        <div className='text-right button-group-list'>
                            <Button
                                className="btn-bordered"
                                onClick={handleClose}
                            >
                                Logout
                            </Button>
                            <Button
                                className="btn-black-bg"
                                onClick={handleSubmit}
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </div>
                {/* Footer */}
            </Box>
        </Modal>
    );
}
