import { useEffect, useState, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppDispatch } from "../../redux/store/store";

import { resetSnackBar } from '../../redux/common/common.slice';
import { resetSalesProposalsStatus } from '../../redux/sales/proposals/proposalSlice';


const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const snackbarTypes = [
    { type: 'error'},
    { type: 'success'},
    { type: 'warning'},
    { type: 'info'},
]

export default function SnackbarComponent(props: any) {

    const { snackObj } = props;

    // component states
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<any>(null)
    const [transition, setTransition] = useState<any>(undefined);
    const [state, setState] = useState<any>({
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;

    // hooks
    const dispatch = useAppDispatch();
    useEffect(() => {
        if(snackObj.open){
            setTransition(() => TransitionRight);
            setState({
                vertical: 'top',
                horizontal: 'right'
            });
            setType(snackbarTypes[snackObj?.type])            
            // to witness the incoming animation
            setTimeout(() => {
                setOpen(true);
            }, 0)
        }
    }, [snackObj])

    // component functions
    function TransitionRight(props: any) {
       return <Slide {...props} direction="right" />;
    }
    const handleClose = () => {
        setOpen(false);
        // call redux action top clean snackobj
        dispatch(resetSnackBar());
        dispatch(resetSalesProposalsStatus());
    };
  
    return (
        <>
            {snackObj.open && (
                <Snackbar
                    open={open} autoHideDuration={6000} onClose={handleClose}
                    anchorOrigin={{ vertical, horizontal }}
                    TransitionComponent={transition}
                    message="I love snacks"
                    key={transition ? transition.name : ''}
                >
                    <Alert onClose={handleClose} severity={type?.type} sx={{ width: '100%' }}>
                        {snackObj?.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}

// possible snackbarObjs
// {
//     type: 0/1/2/3,
//     message: "this is a success message", any string
// }