import { createAsyncThunk } from '@reduxjs/toolkit';
import { rolesService } from '../../services/roles/roles.service';
import { showHideLoader } from '../common/common.slice';

// Async Thunk Actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// Get All Roles Action
const getRolesAction = createAsyncThunk(
  'getRolesSlice/rolesAction', (payload: { perPage: string, page: string}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return rolesService.getRolesService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Create Role Action
const createRolesAction = createAsyncThunk(
  'createRolesAction/rolesAction', (payload: { name: string, permissions: any}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return rolesService.createRolesService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Create Role Action
const editRolesAction = createAsyncThunk(
  'editRolesAction/rolesAction', (payload: { id:string, name: string, permissions: any}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return rolesService.editRolesService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Export All Roles
export const rolesActions = {
  getRolesAction,
  createRolesAction,
  editRolesAction
}

