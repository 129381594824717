import { setSnackBar } from "../../redux/common/common.slice";


export const snackbarHandler = (res: any, dispatch: any) => {

    if (res?.payload?.data?.status === 200) {
        const snackObj: any = {
            type: 1,
            message: res?.payload?.data?.message
        }
        dispatch(setSnackBar(snackObj));
    }else if(res?.payload?.status === 200){
        const snackObj: any = {
            type: 1,
            message: res?.message
        }
        dispatch(setSnackBar(snackObj));
    } else if (res?.payload?.status < 500 && res?.payload?.data?.status < 500) {
        if (res?.payload?.data?.error?.details) {
            const snackObj: any = {
                type: 0,
                message: res?.payload?.data?.error?.details[0]?.message
            }
            dispatch(setSnackBar(snackObj));
        } else {
            const snackObj: any = {
                type: 0,
                message: res?.payload?.data?.message
            }
            dispatch(setSnackBar(snackObj));
        }
    } else {
        const snackObj: any = {
            type: 0,
            message: "Something Went Wrong - Please Try Again."
        }
        dispatch(setSnackBar(snackObj));
    }
}