import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationService } from '../../../services/backOffice/notifications/notifications.service';
import { showHideLoader } from '../../common/common.slice';

// get notification config action
const getRuleConfigAction = createAsyncThunk('notificationsSlice/getRuleConfigAction', ( payload, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));

    return notificationService.getRuleConfigService()
        .then((response) => {
          dispatch(showHideLoader(false));
          return response
        })
        .catch((error: { response: unknown; }) => {
          dispatch(showHideLoader(false));
          return rejectWithValue(error?.response)
        })
    }
);

// get notifications list action
const getNotificationsListAction = createAsyncThunk('notificationsSlice/getNotificationsListAction', ( payload, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return notificationService.getNotificationsListService()
        .then((response) => {
          dispatch(showHideLoader(false));
          return response
        })
        .catch((error: { response: unknown; }) => {
          dispatch(showHideLoader(false));
          return rejectWithValue(error?.response)
        })
    }
);

// get notification details action
const getNotificationDetailsAction = createAsyncThunk('notificationsSlice/getNotificationDetailsAction', ( payload: any, { dispatch, rejectWithValue }) => {
  dispatch(showHideLoader(true));

  return notificationService.getNotificationDetailsService(payload)
      .then((response) => {
        dispatch(showHideLoader(false));
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response)
      })
  }
);


// create notificationaction
const createNotificationAction = createAsyncThunk('notificationsSlice/createNotificationAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    return notificationService.createNotificationService(payload)
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// edit notification action
const editNotificationAction = createAsyncThunk('notificationsSlice/editNotificationAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
  dispatch(showHideLoader(true));

  return notificationService.createNotificationService(payload)
      .then((response) => {
          dispatch(showHideLoader(false));
          return response;
      })
      .catch((error: {response: unknown;}) => {
          dispatch(showHideLoader(false));
          return rejectWithValue(error?.response);
      });
});

// activate notification action
const activateNotificationAction = createAsyncThunk('notificationsSlice/activateNotificationAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
  // dispatch(showHideLoader(true));

  return notificationService.activateNotificationService(payload)
      .then((response) => {
          // dispatch(showHideLoader(false));
          return response;
      })
      .catch((error: {response: unknown;}) => {
          // dispatch(showHideLoader(false));
          return rejectWithValue(error?.response);
      });
});

// delete notification action
const deleteNotificationDetailsAction = createAsyncThunk('notificationsSlice/deleteNotificationDetailsAction', ( payload: any, { dispatch, rejectWithValue }) => {
  dispatch(showHideLoader(true));

  return notificationService.deleteNotificationService(payload)
      .then((response) => {
        dispatch(showHideLoader(false));
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response)
      })
  }
);

// create notificationaction
const previewEmailAction = createAsyncThunk('notificationsSlice/previewEmailAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
  dispatch(showHideLoader(true));

  return notificationService.previewEmailService(payload)
      .then((response) => {
          dispatch(showHideLoader(false));
          return response;
      })
      .catch((error: {response: unknown;}) => {
          dispatch(showHideLoader(false));
          return rejectWithValue(error?.response);
      });
});


// export all common actions
export const notificationsActions = {
    getRuleConfigAction,
    getNotificationsListAction,
    getNotificationDetailsAction,
    createNotificationAction,
    editNotificationAction,
    activateNotificationAction,
    deleteNotificationDetailsAction,
    previewEmailAction
}