import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authSlice  from '../auth/auth.slice';
import userSlice  from '../users/list/user.slice';
import createUsers  from '../users/create/user.slice';
import rolesSlice  from '../roles/roles.slice';
import projectsSlice  from '../projects/projects.slice';
import notificationsSlice from '../backOffice/notifications/notifications.slice'
import officesSlice from '../offices/offices.slice';
import salesProjectsSlice from '../sales/projects/projects.slice';
import pmDashboardSlice from '../projectManagement/dashboard/dashboard.slice';
import pmProjectsSlice from "../projectManagement/pmProjects/pmProjects.slice"
import fmDashboardSlice from '../financeManagement/dashboard/dashboard.slice';
import fmProjectsSlice from "../financeManagement/fmProjects/fmProjects.slice"

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import commonSlice from '../common/common.slice';
import proposalSlice from '../sales/proposals/proposalSlice';

export const store = configureStore({
  reducer: {
    login: authSlice,
    common : commonSlice,
    users: userSlice,
    createUsers: createUsers,
    roles: rolesSlice,
    projects: projectsSlice,
    notifications: notificationsSlice,
    offices: officesSlice,
    salesProjects: salesProjectsSlice,
    salesProposals: proposalSlice,
    pmDashboard: pmDashboardSlice,
    pmProjects: pmProjectsSlice,
    fmDashboard: fmDashboardSlice,
    fmProjects: fmProjectsSlice
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


