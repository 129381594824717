import http from "../http";

// export all dashboard services 
export const pmProjectsService = {
    getProjectListService,
    getProjectInvoicesListService,
    getProjectInvoiceDetailsService,
    updateInvoiceService,
    releaseInvoiceService,
    uploadPdfForInvoiceService,
    uploadPdfForChangeOrderService,
    projectOverviewDetailsService,
    getProjectTimelineListService,
    updateTaskConnectionsService,
    getProjectTasksService,
    projectOverviewTaskDetailsService,
    projectMilestonesService,
    getProjectPhasesList,
    addProjectTaskService,
    updateProjectTaskService,
    deleteProjectTaskService,
    getChangeOrderService,
    getChangeOrderItemsService,
    createChangeOrderService,
    saveChangeOrderService,
    getChangeOrderDetailsService,
    getChangeOrderTermsService,
    saveChangeOrderNotesService,
    saveChangeOrderIntroService,
    uploadChangeOrderPdfService,
    changeOrderAttachmentListService,
    removeChangeOrderAttachmentService,
    exportChangeOrderService,
    deleteChangeOrderService,
    duplicateChangeOrderService,
    getCOPaymentScheduleService,
    updateCOPaymentScheduleService,
    rfpListService,
    createRfpService,
    updateRfpService,
    unreleaseChangeOrder,
    rearrangeProjectOverviewDetailsService,
    generateZip
}
// get projects list service
function getProjectListService(payload: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.id}/projects`);
}

// get project-center invoices list Service
function getProjectInvoicesListService(payload: {pm_id:string, dealId: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pm_id}/project/${payload?.dealId}/invoices`);
}

// get project-center invoice details service
function getProjectInvoiceDetailsService(payload: {pm_id:string, dealId: string, invoiceId: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pm_id}/project/${payload?.dealId}/invoice/${payload?.invoiceId}`);
}

// update invoice service
function updateInvoiceService(payload: {id:any, dealId: string, invoiceId: string, data: any}){
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.id}/project/${payload?.dealId}/invoice/${payload?.invoiceId}`, {
    ...payload?.data
  });
}

function releaseInvoiceService(payload: {pm_id:any, dealId: string, invoiceId: string}){
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pm_id}/project/${payload?.dealId}/invoice/${payload?.invoiceId}/release`);
}

function uploadPdfForInvoiceService(payload: any){
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.dealId}/invoice/${payload?.invoiceId}/upload`,
    payload?.data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

function uploadPdfForChangeOrderService(payload: any){
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${payload?.id}/sign`,
    payload?.data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

// get project overview details service
function projectOverviewDetailsService(payload: any){
// 447abd28-e8ec-4326-b638-afdca1c0658b
// use this id for testing. later replace it with logged in user id
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.id}/project/${payload?.projectId}/overview`, {
    sort: {

    },
    tab: payload?.tab? payload?.tab: ""
  });
}

// get project overview details service
function rearrangeProjectOverviewDetailsService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.userId}/project/${payload?.projectId}/item-sequence`, {
    task: payload?.tasks ? payload?.tasks : []
  });
}

// get project tasks list for gantt chart service
// change
function getProjectTimelineListService(payload: any){
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}/${payload?.timeline?'timeline-listing': 'timeline'}`);
}

// update project task connections for gantt chart service
// change
function updateTaskConnectionsService(payload: any){
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/task/${payload?.taskId}/connections`, {
    connections: payload?.data
  });
}

// get phase-wise tasks list for a project service
// change
function getProjectTasksService(payload: any){
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/tasks`);
}

// get project overview task details service
// change
function projectOverviewTaskDetailsService(payload: any){
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/task/${payload?.taskId}`);
}

// get valid project milestones to link to task
function projectMilestonesService(payload: any){
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/unreleased-invoices`);
}


// get all project phases service
function getProjectPhasesList(payload: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}/phases`);
}

// Add project task service
// change
function addProjectTaskService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/task`, {
    ...payload?.taskObj
  })
}

// update project task service
// change
function updateProjectTaskService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/task/${payload?.taskId}`, {
    ...payload?.taskObj
  })
}

// delete project task service
// change
function deleteProjectTaskService(payload: any) {
  return http.delete(`${process.env.REACT_APP_BASE_URL}/pm/${payload?.pmId}/project/${payload?.projectId}${payload?.timeline?'/timeline':''}/task/${payload?.taskId}`);
}

// get change-order Service
function getChangeOrderService(payload: {dealId:any, sort:any}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/${payload?.dealId}/change-order/list`, {
    sort: payload.sort
  });
}

// get change-order items
function getChangeOrderItemsService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/change-order/get-item`, payload);
}

// create change order
function createChangeOrderService(dealId:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/${dealId}/change-order`);
}

// save change order
function saveChangeOrderService(change_order_id:any, payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${change_order_id}/items`, payload);
}

// get change order details
function getChangeOrderDetailsService(change_order_id:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/project/change-Order/${change_order_id}`);
}

// get change order terms
function getChangeOrderTermsService(change_order_id:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/change-order/${change_order_id}/terms`);
}

// save change order notes
function saveChangeOrderNotesService(change_orde_id:any,payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/change-order/${change_orde_id}/notes`, payload);
}

// save change order Intro
function saveChangeOrderIntroService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/change-order/${payload?.change_order_id}/terms`, payload?.data)
}

// upload change order pdf service
function uploadChangeOrderPdfService(payload:{formData:any, id:any}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${payload?.id}/attachment`,
  payload?.formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  )
}

// uploaded change order doc list service
function changeOrderAttachmentListService(change_order_id:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${change_order_id}/attachment`)
}

// remove uploaded change order attachment service
function removeChangeOrderAttachmentService(attachmentId:any) {
  return http.delete(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/attachment/${attachmentId}`)
}

// view as pdf and release service
function exportChangeOrderService(change_order_id:any,payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${change_order_id}/pdf`, payload)
}

// delete change order service
function deleteChangeOrderService(change_order_id:any) {
  return http.delete(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${change_order_id}`)
}

// duplicate change order service
function duplicateChangeOrderService(change_order_id:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${change_order_id}/duplicate`)
}

// get change order payment schedule 
function getCOPaymentScheduleService(change_order_id:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/pm/get-payment-schedule/${change_order_id}`)
}

// get change order payment schedule 
function updateCOPaymentScheduleService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/save-payment-schedule`, {
    ...payload
  })
}

// Create rfp service
function createRfpService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/${payload.dealId}/rfp/${payload.id}`, payload.body)
}

// rfp listing service
function rfpListService(payload: {dealId:any, sort:any}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/${payload?.dealId}/rfp`, {
    sort: payload.sort
  });
}

// rfp listing service
function updateRfpService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/project/${payload?.dealId}/rfp/${payload?.id}`, payload?.body)
}

// Unrelease change order
function unreleaseChangeOrder(change_orde_id:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/pm/project/change-order/${change_orde_id}/unrelease`)
}

// Generate zip 
function generateZip(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/pm/project/rfp/${payload?.id}/generate-rfp-zip`, payload?.body)
}
