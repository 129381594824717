import http from "../http";

// export all common services 
export const commonService = {
    getUsersList,
    getLabelsList,
    addNewLabelService,
    getTableauToken,
    addNewVendorService
}

// get all users service
function getUsersList() {
    return http.get(`${process.env.REACT_APP_BASE_URL}/projects/users`);
}

// get all labels service
function getLabelsList(payload: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL}/projects/labels?referenceType=${payload?.referenceType}`);
}

// add new label service
function addNewLabelService(payload: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/projects/label`, {
        ...payload
    });
}

// add new label service
function getTableauToken() {
    return http.post(`${process.env.REACT_APP_BASE_URL}/login/tablue`);
}
// add new vendor
function addNewVendorService(payload: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/vendor`, payload);
}
