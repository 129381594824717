import axios from 'axios';

import { store } from '../redux/store/store';
import { setLoginModal } from '../redux/auth/auth.slice';

import { seamlessLoginUrls } from '../Utils/constants';
import { containsSimilarUrl } from '../Utils/helperfunction/helper';

const headers = {
  "Content-type": "application/json",
  accept: "application/json",
  // 'Access-Control-Allow-Origin' : 'http://localhost:3000/',
  // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config: any) => {

  if (localStorage.getItem("token")) {
    if (config?.headers) config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }

  if (config?.data?.params?.use_form_data || config?.params?.use_form_data) {
    if (config?.headers) config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  delete config?.params;
  delete config?.data?.params;

  return config;
});

axios.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // response.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000/';
    // response.headers['Access-Control-Allow-Credentials'] = true
    return response;
  },
  (error:any) => RejectFunction(error),
);

const RejectFunction = (error: any) => {
    const dispatch = store.dispatch;
    
    if (error?.response?.status === 401 && window.location.pathname !== "/login") {

      // check if the request was a get request 
      const url = error.config.url;
      const noRefresh = containsSimilarUrl(url, seamlessLoginUrls);
      // set list of API end-points that if encountered must not refresh the page 
      const payload:any = {
        // refresh: error.config.method === 'get'? true: false
        refresh: !noRefresh
      }
      // check if there is a token in the local storage.
      // if not, redirect them to the login page.
      // show modal only if the token is there.
      // render modal to re-enter password here.
      const token = localStorage.getItem('token');
      if(token) {
        dispatch(setLoginModal(payload));
      }else{
        window.location.pathname = '/unauthorized'
      }
    }
    //something with error
    return Promise.reject(error);
}
 

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  headers,
};
