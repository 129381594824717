import http from "../../http";

// export all common services 
export const notificationService = {
    getRuleConfigService,
    getNotificationsListService,
    getNotificationDetailsService,
    createNotificationService,
    editNotificationService,
    activateNotificationService,
    deleteNotificationService,
    previewEmailService
}

// get notification config service
function getRuleConfigService() {
    return http.get(`${process.env.REACT_APP_BASE_URL}/notification/rule/config`);
}

// get notification config service
function getNotificationsListService() {
    return http.get(`${process.env.REACT_APP_BASE_URL}/notification/rules`);
}

// get all users service
function getNotificationDetailsService(payload: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL}/notification/rule/${payload?.notificationId}`);
}

// create new notification service
function createNotificationService(payload: any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/notification/rule`, payload);
}

// edit notification service
function editNotificationService(payload: any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/notification/rule`, payload);
}

// set active/inactive notificatoin service
function activateNotificationService(payload: any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/notification/rule/${payload?.notificationId}/activate`, {
        activate: payload?.activate
    });
}

// delete notification service
function deleteNotificationService(payload: any) {
    return http.delete(`${process.env.REACT_APP_BASE_URL}/notification/rule/${payload?.notificationId}`);
}

// preview email service
function previewEmailService(payload: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/notification/rule/preview`, payload);
}


