import http from "../http";

// export all dashboard services 
export const fmDashboardService = {
    getAllInvoiceListing
}

// get all-invoices listing
function getAllInvoiceListing({payload}: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL}/finance/invoices-listing/?page=${payload?.page}&perPage=${payload?.perPage}`);
}