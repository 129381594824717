import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { userActions } from './user.actions';

export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}


const userSlice = createSlice({
  name: "users",
  initialState: {
    usersListData: null,
    pagination: {},
    error: "",
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       // -------- cases of Get all users api data -------
       .addCase(userActions.getUsersAction.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(userActions.getUsersAction.fulfilled, (state, action) => {
        if (action.payload.data.status===200) {
          state.loading = false;
          state.usersListData= action.payload.data.data;
          state.pagination={...action.payload.data.pagination}
          state.error = "";
        } else {
          state.loading = false;
          state.error = "unable to fetch users List.";
        }
      })
      .addCase(userActions.getUsersAction.rejected, (state, action:any) => {
        state.loading = false;
        state.error = action?.payload?.data?.message || "";
      });
  },
  
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;


export default userSlice.reducer;
