import { createSlice } from '@reduxjs/toolkit';
import { fmDashboardActions } from './dashboard.action';


interface DashboardState {
  loading: boolean;
  success: any;
  error: any;

  allInvoiceListing: any;
  allInvoiceListingError: any;
  allInvoiceListingPaginations: any;
  allInvoiceListingFiltersData: any;
}
const initialState: DashboardState = {
  loading: false,
  success: null,
  error: null,

  allInvoiceListing: [],
  allInvoiceListingError: null,
  allInvoiceListingPaginations: {},
  allInvoiceListingFiltersData: null,
};

const fmDashboardSlice = createSlice({
  name: "fmDashboardSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      // -------- cases of project manager assigned projects list api data -------
      .addCase(fmDashboardActions.getAllInvoicesListingAction.pending, (state, action) => {
        state.allInvoiceListingError = "";
        state.allInvoiceListing = [];
        state.allInvoiceListingPaginations = {};
        state.allInvoiceListingFiltersData = action?.meta?.arg;
      })
      .addCase(fmDashboardActions.getAllInvoicesListingAction.fulfilled, (state, action) => {
            if (action.payload.data.data) {
              state.allInvoiceListing= action.payload.data.data;
              state.allInvoiceListingError = "";
              state.allInvoiceListingPaginations = action.payload.data.pagination;
            } else {
              state.error = "Unable to fetch projects list";
              state.allInvoiceListingPaginations = {}
            }
      })
      .addCase(fmDashboardActions.getAllInvoicesListingAction.rejected, (state, action:any) => {
            state.allInvoiceListing = [];
            state.allInvoiceListingError = action?.error?.message || "Unable to fetch projects list";
            state.allInvoiceListingPaginations = {}
      })
  },
});

export const { } = fmDashboardSlice.actions;
export default fmDashboardSlice.reducer;