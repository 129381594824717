import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { createUserActions } from './user.actions';

export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}


const createUserSlice = createSlice({
  name: "createUsers",
  initialState: {
    createdUserData: null,
    pagination: {},
    error: "",
    loading: false,
    validationError: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       // -------- cases of Create User api data -------
       .addCase(createUserActions.createUsersAction.pending, (state, action) => {
        state.error = "";
        state.loading = true;
        state.validationError = [];
      })
      .addCase(createUserActions.createUsersAction.fulfilled, (state, action) => {
        state.loading = false;
        state.createdUserData = action?.payload?.data?.message;
        state.error = "";
      })
      .addCase(createUserActions.createUsersAction.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action?.payload?.data?.message;
      });
  },
  
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;


export default createUserSlice.reducer;
