
import { useMemo } from "react";

import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";

import palette from "./palette";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";



export default function ThemeProvider({ children }) {
  // Define your custom font family
  const customFontFamily = '"Inter", sans-serif';
  const letterSpacing = 0;

  // Overwrite default theme options
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography: {
        fontFamily: customFontFamily,
        letterSpacing:letterSpacing,
      },
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
