import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectsService } from '../../../services/sales/projects.service';
import { showHideLoader } from '../../common/common.slice';

// Get Projects list Action
const getProjectsListAction = createAsyncThunk(
  'salesSlice/getProjectsListAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectsListService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// activate deal action
const activateDealAction = createAsyncThunk(
  'salesSlice/activateDealAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.activateDealService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// activate deal action
const resendInviteAction = createAsyncThunk(
  'salesSlice/resendInviteAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.resendInviteService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Get project details action
const getProjectDetailsAction = createAsyncThunk(
  'salesSlice/getProjectDetailsAction', (payload: any, { dispatch, rejectWithValue }) => {
    if(payload.loader){
      dispatch(showHideLoader(true));
    }
    return projectsService.projectDetailsService(payload.projectId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// save project details rooms action
// loader not required
const postProjectDetailsRoomAction = createAsyncThunk(
  'salesSlice/postProjectDetailsRoomAction', (payload: any, { dispatch, rejectWithValue }) => {
    if(payload.loader){
      dispatch(showHideLoader(true));
    }
    return projectsService.projectDetailsRoomsUpdateService(payload.data)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get project details notes list action
const getProjectDetailsNotesListingAction = createAsyncThunk(
  'salesSlice/getProjectDetailsNotesListingAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectDetailsNotesListingService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// create notes action
const postProjectDetailsNoteAction = createAsyncThunk(
  'salesSlice/postProjectDetailsNoteAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectDetailsCreateNoteService(payload)
      .then((response) => {
        setTimeout(() => {
          // dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// update project details notea action
const updateProjectDetailsNoteAction = createAsyncThunk(
  'salesSlice/updateProjectDetailsNoteAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectDetailsUpdateNoteService(payload)
      .then((response) => {
        setTimeout(() => {
          // dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false));
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// delete project details notes action
const deleteProjectDetailsNoteAction = createAsyncThunk(
  'salesSlice/deleteProjectDetailsNoteAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectDetailsDeleteNoteService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Get uploaded document media Action
const getUploadedDocumentsMediaAction = createAsyncThunk(
  'projectsSlice/getUploadedDocumentsMediaAction', (payload : any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.getUploadedDocListService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// Upload Documents/Media Action
const uploadDocumentsMediaAction = createAsyncThunk(
  'projectsSlice/uploadDocumentsMediaAction', ({payload,dealId}:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.uploadDocumentsMediaService({payload,dealId})
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// Update label FileAction
const updateLabelFileAction = createAsyncThunk(
  'projectsSlice/updateLabelFileAction', ({id,payload}:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.updateLabelService({id, payload})
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// Delete Uploaded FileAction
const deleteUploadedFileAction = createAsyncThunk(
  'projectsSlice/deleteUploadedFileAction', (payload :{id: string, documentType: string}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.deleteUploadedFileService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// get uploaded document/ media uploaded files Action
const getDocumentMediaUploadedFilesAction = createAsyncThunk(
  'projectsSlice/getDocumentMediaUploadedFilesAction', (payload :any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.getDocumentMediaFilesService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// get document/ media folders Action
const getDocumentMediaFoldersAction = createAsyncThunk(
  'projectsSlice/getDocumentMediaFoldersAction', (payload :any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.getDocumentMediaFoldersService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// upload document/ media files Action
const uploadDocumentMediaFilessAction = createAsyncThunk(
  'projectsSlice/uploadDocumentMediaFilessAction', ({payload, dealId, loader}:any, { dispatch, rejectWithValue }) => {
    if(loader) {
      dispatch(showHideLoader(true));
    }
    return projectsService.uploadDocumentMediaFilesService(payload, dealId, loader)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// update document/ media label Action
const updateDocumentMediaLabelAction = createAsyncThunk(
  'projectsSlice/updateDocumentMediaLabelAction', ({ payload, projectId }: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.updateDocumentMediaLabelService(payload, projectId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// delete document/ media file Action
const deleteDocumentMediaFileAction = createAsyncThunk(
  'projectsSlice/deleteDocumentMediaFileAction', ({projectId, documentType}: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.deleteDocumentMediaFileService(projectId, documentType)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// delete document/ media file Action
const downloadFolderAction = createAsyncThunk(
  'projectsSlice/downloadFolderAction', ({formData, projectId}: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.downloadFolderService(formData, projectId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get project proposals Action
const getProjectProposalsAction = createAsyncThunk(
  'projectsSlice/getProjectProposalsAction', (projectId: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.getProjectProposalsService(projectId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Export all Projects Action
export const salesProjectsActions = {
  getProjectsListAction,
  activateDealAction,
  resendInviteAction,
  getProjectDetailsAction,
  postProjectDetailsRoomAction,
  getProjectDetailsNotesListingAction,
  postProjectDetailsNoteAction,
  updateProjectDetailsNoteAction,
  deleteProjectDetailsNoteAction,
  getUploadedDocumentsMediaAction,
  uploadDocumentsMediaAction,
  updateLabelFileAction,
  deleteUploadedFileAction,
  getDocumentMediaUploadedFilesAction,
  getDocumentMediaFoldersAction,
  uploadDocumentMediaFilessAction,
  updateDocumentMediaLabelAction,
  deleteDocumentMediaFileAction,
  downloadFolderAction,
  getProjectProposalsAction
}

