import http from "../http";

// Export all user Service
export const userService = {
  userListService,
  createUserService,
  getClientPortalAccessToken
};

// Get All User Service
function userListService(payload : {perPage: string, page: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/user/?perPage=${payload.perPage}&page=${payload.page}`, );
}

// Create User Service
function createUserService(payload : { firstName: string, lastName: string, email: string, password: string, userRole: string}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/`, {
    ...payload
  });
}

// get client portal access token
function getClientPortalAccessToken(id: string) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/get-token/${id}`)
}

 

