import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store/store';
import { authActions } from './auth.actions';
import jwt_decode from "jwt-decode";

// User Token
var authToken = localStorage.getItem("token") || null;
var tempToken = localStorage.getItem("temp-token") || null;

export interface authTypes {
  isAuthenticated: boolean,
  token: null|string,
  tempToken: null|string,
  userObject: any,
  tempUserObject: any,
  error: any,
  loading: boolean,
  loginSuccess: boolean,
  optSent: any,
  otpVerification: boolean,
  verifiedOtp: any,
  resetPasswordSuccess: boolean,
  userRegistered: any,
  loginModal: {
      status: boolean,
      refresh: boolean,
    }
}

const initialState: authTypes = {
    isAuthenticated: !!localStorage.getItem("user"),
    token: null,
    tempToken: null,
    tempUserObject: tempToken? jwt_decode(tempToken) : null,
    userObject: authToken ? jwt_decode(authToken) : null,
    error: "",
    loading: false,
    loginSuccess: false,
    optSent: null,
    otpVerification: false,
    verifiedOtp: "",
    resetPasswordSuccess: false,
    userRegistered: "",
    loginModal: {
      status: false,
      refresh: false,
    }
  }

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // -------- login modal --------------------
    setLoginModal: (state: any, action:any) => {
      state.loginModal = {
        status: true,
        refresh: action?.payload.refresh
      }
    },
    unsetLoginModal: (state: any) => {
      state.loginModal = {
        status: false,
        refresh: false
      }
    },
    // -------- Logout state ------
    logout: (state: any) => {
      state.isAuthenticated = false;
      state.token = null;
      state.userObject = null;
      localStorage.clear();
      state.error = "";
    },
    // -------- Logout state ------
    stopImpersonification: (state) => {
      
      // state is not reliable. use localStorage instead.
      const tempToken = localStorage.getItem('temp-token');

      // reset token and user object
      state.token = tempToken;
      state.userObject = jwt_decode(tempToken??"");
      localStorage.setItem('token', tempToken?? "");

      state.error = "";

      // reset temp token and temp user object
      state.tempToken = null;
      state.tempUserObject = null;
      localStorage.removeItem('temp-token');
    },
    // -------- Reset all the auth error state for forgotpassword, login and resetpassword. ------
    resetError: (state) => {
      state.loading = false;
      state.error = "";
      state.loginSuccess = false;
      state.optSent = null;
      state.resetPasswordSuccess = false;
      state.userRegistered = "";
    }
  },
  extraReducers: (builder) => {
    builder
       // -------- cases of Login api data -------
       .addCase(authActions.loginAction.pending, (state, action) => {
        state.userObject = null;
        state.error = "";
        state.loading = true;
        state.loginSuccess = false;
      })
      .addCase(authActions.loginAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.status===200) {
          state.isAuthenticated = true;
          state.userObject = jwt_decode(action.payload.data.data?.token);
          localStorage.setItem("token", action.payload.data.data.token);
          state.token = action.payload.data.data.token;
          state.loginSuccess = true;
          state.error = "";
        } else {
          state.isAuthenticated = false;
          state.loginSuccess = false;
          localStorage.removeItem("token");
          state.error = "Unable to login properly.";
        }
      })
      .addCase(authActions.loginAction.rejected, (state, action: any) => {
        state.userObject = null;
        state.loading = false;
        state.loginSuccess = false;
        state.error = action?.payload?.data?.message;
      })
      // -------- cases of impersonification api data -------
       .addCase(authActions.impersonificationAction.pending, (state, action) => {
        state.tempToken = null;
        state.tempUserObject = null;
      })
      .addCase(authActions.impersonificationAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.status===200) {
          const token = localStorage.getItem('token');
          // we save original user data in a temporary location while replacing the new user
          // temp user object
          state.tempUserObject = jwt_decode(token??"");
          state.userObject = jwt_decode(action.payload.data.data?.token);

          // temp user token
          localStorage.setItem("temp-token", token?? "");
          localStorage.setItem("token", action.payload.data.data.token);
          // temp user token
          state.tempToken = token;
          state.token = action.payload.data.data.token;
        }
      })
      .addCase(authActions.impersonificationAction.rejected, (state, action: any) => {
        state.tempUserObject = null;
      })


      // -------- cases of token replace api data -------
       .addCase(authActions.replaceTokenAction.pending, (state, action) => {
        
        // keep the state unchanged
        if(false){
          state.userObject = {permissions: []};
          state.error = "";
          state.loading = true;
          state.loginSuccess = false;
        }
      })
      .addCase(authActions.replaceTokenAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.status===200) {
          state.isAuthenticated = true;
          // state.userObject = jwt_decode(action.payload.data.data?.token);
          localStorage.setItem("token", action.payload.data.data.token);
          state.token = action.payload.data.data.token;
          // state.loginSuccess = true;
          // state.error = "";
        } else {
          state.isAuthenticated = false;
          // state.loginSuccess = false;
          // localStorage.removeItem("token");
          // state.error = "Unable to login properly.";
        }
      })
      .addCase(authActions.replaceTokenAction.rejected, (state, action: any) => {
        // state.userObject = {permissions: []};
        state.loading = false;
        state.loginSuccess = false;
        // state.error = action?.payload?.data?.message;
      })

      
      // -------- cases of sending OTP api request -------
      .addCase(authActions.sendOtpAction.pending, (state, action) => {
        state.optSent = null
        state.error = "";
        state.loading = true;
      })
      .addCase(authActions.sendOtpAction.fulfilled, (state, action) => {
        state.optSent = action?.payload?.data?.message
        state.error = "";
        state.loading = false;
      })
      .addCase(authActions.sendOtpAction.rejected, (state, action: any) => {
        state.optSent = null
        state.loading = false;
        if(action.payload.status === 401){
          state.error = action?.payload?.data?.message || "";
        }else if(action?.payload?.status === 400){
          state.error = action?.payload?.data.message || "";
        }else if(action?.payload?.status === 422){
          state.error = action?.payload?.data.details[0].message || "";
        }else {
          state.error = action?.error?.message || "";
        }
      })
      // -------- cases of verifying OTP api request -------
      .addCase(authActions.verifyOtpAction.pending, (state, action) => {
        state.otpVerification = false
        state.verifiedOtp = action?.meta?.arg?.code
        state.error = "";
        state.loading = true;
      })
      .addCase(authActions.verifyOtpAction.fulfilled, (state, action) => {
        state.otpVerification = action?.payload?.data?.success
        state.error = "";
        state.loading = false;
      })
      .addCase(authActions.verifyOtpAction.rejected, (state, action: any) => {
        state.otpVerification = false
        state.loading = false;
        if(action?.payload?.status === 401){
          state.error = action?.payload?.data?.message || "";
        }else if(action?.payload?.status === 400){
          state.error = action?.payload?.data?.message || "";
        }else if(action?.payload?.status === 422){
          state.error = action?.payload?.data?.details[0].message || "";
        }else {
          state.error = action?.error?.message || "";
        }
      })
      // -------- cases of reset password api request -------
      .addCase(authActions.resetPasswordAction.pending, (state, action) => {
        state.resetPasswordSuccess = false;
        state.error = "";
        state.loading = true;
      })
      .addCase(authActions.resetPasswordAction.fulfilled, (state, action) => {
        state.resetPasswordSuccess = action?.payload?.data?.message;
        state.error = "";
        state.loading = false;
      })
      .addCase(authActions.resetPasswordAction.rejected, (state, action: any) => {
        state.resetPasswordSuccess = false;
        state.loading = false;
        if(action?.payload?.status === 401){
          state.error = action?.payload?.data?.message || "";
        }else if(action?.payload?.status === 400){
          state.error = action?.payload?.data?.message || "";
        }else if(action?.payload?.status === 422){
          state.error = action?.payload?.data?.details[0].message || "";
        }else {
          state.error = action?.error?.message || "";
        }
      })
  },
});

// Export Logout
export const { logout, setLoginModal, unsetLoginModal, stopImpersonification } = authSlice.actions;

// Export ResetError
export const {resetError} = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const token = (state: RootState) => state.login.token;


export default authSlice.reducer;
