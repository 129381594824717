import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectsService } from '../../services/projects/projects.service';
import { showHideLoader } from '../common/common.slice';

// Async Thunk Actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// Project Autocomplete Action
const projectsAutocompleteAction = createAsyncThunk(
  'projectsSlice/projectsAutocompleteAction', (query: string, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectsAutocompleteService(query)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Project Users List Action
const getUsersList = createAsyncThunk(
  'projectsSlice/getUsersList', (_query, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.getUsersListService()
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Create Project Action
const createProjectAction = createAsyncThunk(
  'projectsSlice/createProjectAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.createProjectService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Update Project Action
const updateProjectAction = createAsyncThunk(
  'projectsSlice/updateProjectAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.updateProjectService(payload)
      .then((response) => {
        dispatch(showHideLoader(false));
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response)
      })
  }
);

// Get Projects list Action
const getProjectsAction = createAsyncThunk(
  'projectsSlice/getProjectsAction', (payload: { perPage: string, page: string, filters: any}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return projectsService.projectListService(payload)
      .then((response) => {
        response.data.data.map((ele: any) => {
          // ele.startDate = ele.startDate ? moment(ele.startDate).format("MMM DD, YYYY") : "-"
          // ele.endDate = ele.endDate ? moment(ele.endDate).format("MMM DD, YYYY") : "-"
          ele.state = ele?.profileCenter ?? 'New York';
          return 
        })
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Export all Projects Action
export const projectsActions = {
  projectsAutocompleteAction,
  getUsersList,
  createProjectAction,
  updateProjectAction,
  getProjectsAction,
}

