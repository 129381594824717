import http from "../http";

// Export all Roles Service
export const rolesService = {
  getRolesService,
  createRolesService,
  editRolesService
};

// Roles List Service
function getRolesService(payload : {perPage: string, page: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/role/?perPage=${payload.perPage}&page=${payload.page}`, );
}

// Create Role Service
function createRolesService(payload : { name: string, permissions: any }) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/role/`, {
    ...payload
  });
}

// Edit Role Service
function editRolesService(payload : { id: string, name: string, permissions: any }) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/role/${payload?.id}`, {
    name: payload?.name,
    permissions: payload?.permissions
  });
}

 

