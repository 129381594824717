import { createSlice } from '@reduxjs/toolkit';
import { dashboardActions } from './dashboard.action';


interface DashboardState {
  loading: boolean;
  success: any;
  error: any;
  assignedProjects: any;
  timelineListing: any;
  notificationsListing: any;
  notificationRead: any;
  todosListing: any;
  todoUpdated: any;
  timelineTaskUpdated: any;
  eventListing: any;
  createdEvent: any;
  editedEvent: any;
  reminderAndContactList: any;
  outlookAuthorize: any
}
const initialState: DashboardState = {
  loading: false,
  success: null,
  error: null,
  assignedProjects: [],
  timelineListing: [],
  notificationsListing: [],
  notificationRead: null,
  todosListing: [],
  todoUpdated: null,
  timelineTaskUpdated: null,
  eventListing: [],
  createdEvent:false,
  editedEvent: false,
  reminderAndContactList:[],
  outlookAuthorize: false
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      // -------- cases of dashboard timeline list api data -------
      .addCase(dashboardActions.getTimelineListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.timelineListing = [];
      })
      .addCase(dashboardActions.getTimelineListAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.timelineListing = action?.payload?.data?.data;
              // resets update timeline task data
              state.timelineTaskUpdated = null;
            }else{
              state.success = null;
              state.timelineListing = [];
              state.error = 'Timeline data could not be fetched.';
            }
      })
      .addCase(dashboardActions.getTimelineListAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.timelineListing = [];
            state.error = action?.payload?.data?.message ?? 'Timeline data could not be fetched.';
      })

      // -------- cases of dashboard notifications api data -------
      .addCase(dashboardActions.getAllNotificationsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.notificationsListing = [];
      })
      .addCase(dashboardActions.getAllNotificationsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.notificationsListing = action?.payload?.data?.data;
        }else{
          state.success = null;
          state.notificationsListing = [];
          state.error = 'Notifications could not be fetched.';
        }
      })
      .addCase(dashboardActions.getAllNotificationsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.notificationsListing = [];
        state.error = action?.payload?.data?.message ?? 'Notifications could not be fetched.';
      })

      // -------- cases of dashboard read notification api data -------
      .addCase(dashboardActions.readNotificationAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.notificationRead = null;
      })
      .addCase(dashboardActions.readNotificationAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.notificationRead = action?.payload?.data?.data;
        }else{
          state.success = null;
          state.notificationRead = null;
          state.error = action?.payload?.data?.message ?? 'Read Notification Failed.';
        }
      })
      .addCase(dashboardActions.readNotificationAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.notificationRead = null;
        state.error = action?.payload?.data?.message ?? 'Read Notification Failed.';
      })
      // -------- cases of dashboard todos list api data -------
      .addCase(dashboardActions.getTodosListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.todosListing = [];
      })
      .addCase(dashboardActions.getTodosListAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.todosListing = action?.payload?.data?.data;

          // reset todo-update data
          state.todoUpdated = null;
        }else {
          state.success = null;
          state.todosListing = [];
          state.error = 'Todos data could not be fetched.';
        }
      })
      .addCase(dashboardActions.getTodosListAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.todosListing = [];
            state.error = action?.payload?.data?.message ?? 'Todos data could not be fetched.';;
      })

      // -------- cases of dashboard update todo api data -------
      .addCase(dashboardActions.updateTodoAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.todoUpdated = null;
      })
      .addCase(dashboardActions.updateTodoAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.todoUpdated = action?.payload?.data;
        }else {
          state.success = null;
          state.todoUpdated = null;
          state.error = 'Todo data could not be updated.';
        }
      })
      .addCase(dashboardActions.updateTodoAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.todoUpdated = null;
        state.error = action?.payload?.data?.message ?? 'Todo data could not be updated.';
      })

      // -------- cases of dashboard update timeline task api data -------
      // although this seems repetetive, this is required to differentiate between todo and timeline task updates
      // as after updating tasks from either list should not update the other list.
      .addCase(dashboardActions.updateTimelineTaskAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.timelineTaskUpdated = null;
      })
      .addCase(dashboardActions.updateTimelineTaskAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.timelineTaskUpdated = action?.payload?.data;
        }else {
          state.success = null;
          state.timelineTaskUpdated = null;
          state.error = 'Todo data could not be updated.';
        }
      })
      .addCase(dashboardActions.updateTimelineTaskAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.timelineTaskUpdated = null;
        state.error = action?.payload?.data?.message ?? 'Todo data could not be updated.';
      })

      // -------- cases of event api data -------
      .addCase(dashboardActions.getAllEventAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.eventListing = [];
      })
      .addCase(dashboardActions.getAllEventAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.eventListing = action?.payload?.data?.data;
        }else {
          state.success = null;
          state.eventListing = [];
          state.error = 'Event list could not be fetched.';
        }
      })
      .addCase(dashboardActions.getAllEventAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.eventListing = [];
        state.error = action?.payload?.data?.message ?? 'Event list could not be fetched.';
      })

      // -------- cases of create new event -------
      .addCase(dashboardActions.createNewEventAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.createdEvent = false;
      })
      .addCase(dashboardActions.createNewEventAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.createdEvent = true;
        }else {
          state.success = null;
          state.createdEvent = false;
          state.error = 'Event creation failed.';
        }
      })
      .addCase(dashboardActions.createNewEventAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.createdEvent = false;
        state.error = action?.payload?.data?.message ?? 'Event creation failed.';
      })

      // -------- cases of edit event -------
      .addCase(dashboardActions.editEventAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.editedEvent = false;
      })
      .addCase(dashboardActions.editEventAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.editedEvent = true;
        }else {
          state.success = null;
          state.editedEvent = false;
          state.error = 'Edit event failed.';
        }
      })
      .addCase(dashboardActions.editEventAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.editedEvent = false;
        state.error = action?.payload?.data?.message ?? 'Edit event failed.';
      })

      // -------- cases of get reminder and contact list -------
      .addCase(dashboardActions.getReminderAndContactListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.reminderAndContactList = [];
      })
      .addCase(dashboardActions.getReminderAndContactListAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status === 200) {
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.reminderAndContactList = action?.payload?.data?.data;
        } else {
          state.success = null;
          state.reminderAndContactList = [];
          state.error = 'Reminder and contact list could not be fetched.';
        }
      })
      .addCase(dashboardActions.getReminderAndContactListAction.rejected, (state, action: any) => {
        state.loading = false;
        state.success = null;
        state.reminderAndContactList = [];
        state.error = action?.payload?.data?.message ?? 'Reminder and contact list could not be fetched.';
      })

      // -------- cases of outlook authorization -------
      .addCase(dashboardActions.outlookAuthorizationAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.outlookAuthorize = false;
      })
      .addCase(dashboardActions.outlookAuthorizationAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status === 200) {
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.outlookAuthorize = action?.payload?.data?.data;
        } else {
          state.success = null;
          state.outlookAuthorize = false;
          state.error = 'Outlook Authorization failed..';
        }
      })
      .addCase(dashboardActions.outlookAuthorizationAction.rejected, (state, action: any) => {
        state.loading = false;
        state.success = null;
        state.outlookAuthorize = false;
        state.error = action?.payload?.data?.message ?? 'Outlook Authorization failed..';
      })

  },
});

export const { } = dashboardSlice.actions;
export default dashboardSlice.reducer;