import { GlobalStyles as MUIGlobalStyles } from "@mui/material";

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        body: {
            margin: 0,
            padding: 0,
            width: "100%",
            height: "100%",
            fontFamily:"'Inter', sans-serif !important",
            background: "#FEFBFC",
            letterSpacing:0,
            fontSize:"12px",
            lineHeight:"15px",
            fontWeight:"400",
          },
      }}
    />
  );

  return inputGlobalStyles;
}
