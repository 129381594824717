import http from "../http";

// Export all Project Service
export const officesService = {
  getOfficesListService,
  getOfficeDetailsService,
  createOfficeService,
  updateOfficeService
};

// Offices list Service
function getOfficesListService(payload: {perPage: string, page: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/offices/?page=${payload.page}&perPage=${payload.perPage}`);
}

// Office details list Service
function getOfficeDetailsService(payload: {id: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/offices/${payload.id}`);
}

// Create Office Service
function createOfficeService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/offices`, {
    ...payload
  });
}

// Update Office Service
function updateOfficeService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/offices/${payload?.id}`, payload?.data);
}

 

