export const PER_PAGE = 10;
export const DATE_FORMAT = "MM/DD/YYYY";
export const jobTypes = [
    "All",
    "Kitchen",
    "Bathroom",
    "Bed Room",
    "Living Room"
];

export const seamlessLoginUrls = [
  // save proposal items
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/save-items`,
  // save proposal item detail
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/<UUID>/group/<UUID>/items/<UUID>`,
  // save proposal group detail
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/<UUID>/group/<UUID>`,
  // save project detail
  `${process.env.REACT_APP_BASE_URL}/projects/<UUID>`,
  // save proposal payment schedule detail
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/save-payment-schedule`,
  // save change order detail
  `${process.env.REACT_APP_BASE_URL}/pm/project/change-order/<UUID>/items`,
  // save project overview tasks
  `${process.env.REACT_APP_BASE_URL}/pm/<UUID>/project/<UUID>/task`
];

export const EliMail = "eli@hellochapter.com"