import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { officesActions } from './offices.actions';

export interface OfficesState {
    loading: boolean;
    error: any;
    success: boolean;
    officesList: any[];
    pagination: any;
    officeDetails: any;
}

const initialState: OfficesState = {
    loading: false,
    error: null,
    success: false,
    officesList: [],
    pagination: null,
    officeDetails: null
}

const officeSlice = createSlice({
    name: 'offices',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            // -------- cases of get offices list api data -------- //
            .addCase(officesActions.getOfficesAction.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
                state.officesList = [];
            })
            .addCase(officesActions.getOfficesAction.fulfilled, (state, action:any) => {
                state.loading = false;
                if(action?.payload?.data?.status===200){
                    state.loading = false;
                    state.error = null;
                    state.success = true;
                    state.officesList = action.payload.data.data.map((office:any) => {
                        return {
                            ...office,
                            officeHeadName: office.officeHead.firstName + ' ' + office.officeHead.lastName
                        }
                    });
                    state.pagination={...action.payload.data.pagination}
                }else{
                    state.success = false;
                    state.officesList = [];
                    state.error = action?.payload?.data?.message ?? 'Failed to fetch offices list';
                }
            })
            .addCase(officesActions.getOfficesAction.rejected, (state, action:any) => {
                state.loading = false;
                state.officesList = [];
                state.success = false;
                state.error = action?.payload?.data?.message ?? 'Failed to fetch offices list';
            })
            // -------- cases of get office details api data -------- //
            .addCase(officesActions.getOfficeDetailsAction.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
                state.officeDetails = null;
            })
            .addCase(officesActions.getOfficeDetailsAction.fulfilled, (state, action:any) => {
                state.loading = false;
                if(action?.payload?.data?.status===200){
                    state.loading = false;
                    state.error = null;
                    state.success = true;
                    state.officeDetails = action.payload.data.data;
                }else{
                    state.success = false;
                    state.officeDetails = null;
                    state.error = action?.payload?.data?.message ?? 'Failed to fetch office details';
                }
            })
            .addCase(officesActions.getOfficeDetailsAction.rejected, (state, action:any) => {
                state.loading = false;
                state.officeDetails = null;
                state.success = false;
                state.error = action?.payload?.data?.message ?? 'Failed to fetch office details';
            })
    }
});

export default officeSlice.reducer;